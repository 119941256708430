import "./styles.css";

document.getElementById("app").innerHTML = `
<div class="container">
<div style="grid-column: 2;">
<h1>Howdy!</h1>
<div>
  We've moved. The team is working on Sequin. You can find us at <a href="https://sequin.io">https://sequin.io</a>.
</div>
<br/>
<div>You should be redirected automatically in 5 seconds.</div>
</div>
</div>
`;
